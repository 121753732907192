import styled from 'styled-components'
import media from 'styled-media-query'
import BackgroundImage from 'gatsby-background-image'
import { Whatsapp } from '@styled-icons/boxicons-logos/Whatsapp'

export const StaffWrapper = styled.div`
  background: #f5f5f5;
  padding-top: 160px;

  ${media.lessThan('medium')`
    padding-top: 120px;
  `}
`

export const Container = styled.div`
  max-width: 1200px;
  margin: auto;
`

export const StaffCard = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  padding-bottom: 60px;

  ${media.lessThan('medium')`
    display: block;
  `}
`

export const TextWrapper = styled.div`
  display: grid;
  grid-template-rows: 120px 1fr 20px;
  padding: 50px 25px;
  background: white;

  ${media.lessThan('medium')`
    display: block;
  `}
`

export const PageTitle = styled.h2`
  text-align: center;
`

export const Border = styled.div`
  height: 6px;
  width: 280px;
  margin: -1rem auto 2rem;
  background: #8b8b8b;
`

export const Image = styled(BackgroundImage)`
  min-height: 660px;

  ${media.lessThan('medium')`
    height: 400px;
  `}
`

export const Title = styled.h3`
  text-transform: uppercase;
`
export const SubTitle = styled.h4`
  font-size: 18px;
`

export const CV = styled.div`
  ${media.lessThan('medium')`
    margin-top: 40px
  `}
`

export const WhatsappIcon = styled(Whatsapp)`
  height: 25px;
  color: #707070;
`
