import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import StaffPage from '../components/StaffPage'
import Contact from '../components/Contact'

const AdvogadosPage = () => (
  <Layout>
    <SEO title="Advogados" />
    <StaffPage />
    <Contact />
  </Layout>
)

export default AdvogadosPage
