import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import * as S from './styled'

const StaffPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        partner: allMarkdownRemark(
          filter: {
            frontmatter: { key: { eq: "staff" }, partner: { eq: true } }
          }
          sort: { fields: frontmatter___title, order: DESC }
        ) {
          edges {
            node {
              frontmatter {
                slug
                oab
                position
                specialty
                title
                whatsapp
                image {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              id
              html
            }
          }
        }
        associated: allMarkdownRemark(
          filter: {
            frontmatter: { key: { eq: "staff" }, partner: { eq: false } }
          }
          sort: { fields: frontmatter___title, order: ASC }
        ) {
          edges {
            node {
              frontmatter {
                slug
                oab
                position
                specialty
                title
                whatsapp
                image {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              html
              id
            }
          }
        }
      }
    `
  )

  const partners = data.partner.edges
  const associates = data.associated.edges

  return (
    <S.StaffWrapper>
      <S.Container>
        <S.PageTitle>Advogados</S.PageTitle>
        <S.Border />
        {partners.map(({ node: partner }) => (
          <S.StaffCard key={partner.id} id={partner.frontmatter.slug}>
            <S.Image
              fluid={partner.frontmatter.image.childImageSharp.fluid}
              alt={partner.frontmatter.title}
            />
            <S.TextWrapper>
              <div>
                <S.Title>{partner.frontmatter.title}</S.Title>
                <S.SubTitle>{partner.frontmatter.position}</S.SubTitle>
                <S.SubTitle>{partner.frontmatter.oab}</S.SubTitle>
              </div>
              <S.CV
                dangerouslySetInnerHTML={{
                  __html: partner.html
                }}
              />
              <a
                href={
                  'https://api.whatsapp.com/send?phone=55' +
                  partner.frontmatter.whatsapp
                }
                alt="WhatsApp"
                aria-label="WhatsApp"
                target="_blank"
                rel="noreferrer"
              >
                <S.WhatsappIcon />
              </a>
            </S.TextWrapper>
          </S.StaffCard>
        ))}

        {associates.map(({ node: associated }) => (
          <S.StaffCard key={associated.id} id={associated.frontmatter.slug}>
            <S.Image
              fluid={associated.frontmatter.image.childImageSharp.fluid}
              alt={associated.frontmatter.title}
            />
            <S.TextWrapper>
              <div>
                <S.Title>{associated.frontmatter.title}</S.Title>
                <S.SubTitle>{associated.frontmatter.position}</S.SubTitle>
                <S.SubTitle>{associated.frontmatter.oab}</S.SubTitle>
              </div>
              <S.CV
                dangerouslySetInnerHTML={{
                  __html: associated.html
                }}
              />
              <a
                href={associated.frontmatter.whatsapp}
                alt="WhatsApp"
                aria-label="WhatsApp"
                target="_blank"
                rel="noreferrer"
              >
                <S.WhatsappIcon />
              </a>
            </S.TextWrapper>
          </S.StaffCard>
        ))}
      </S.Container>
    </S.StaffWrapper>
  )
}

export default StaffPage
